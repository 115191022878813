@use "@/styles/colors";
@use "@/styles/breakpoints";

.header {
  &[data-theme="dark"] {
    .header {
      &__icon svg {
        rect,
        circle {
          stroke: colors.$intense-black;
        }
      }
    }

    .navbar {
      &__menu {
        border-color: colors.$intense-black;

        &:not(.navbar__menu--open) .navbar__menu-link {
          color: colors.$intense-black;
        }
      }

      &__burger-menu-bar {
        background-color: colors.$intense-black;
      }

      .progress {
        background: colors.$intense-black;
      }
    }
  }

  .navbar {
    position: relative;

    &__menu {
      padding: 0.8% 11.5%;
      display: flex;
      justify-content: space-between;
      @include breakpoints.respond(s) {
        padding: 5px 0;
        justify-content: center;
        gap: 4dvw;
      }
      @include breakpoints.respond(xs) {
        padding: 0;
        height: 38px;
        justify-content: center;
        align-items: center;
      }
      border-bottom: 1px solid colors.$intense-white;
      transition: 0.5s;

      &--open {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 0;
        width: 100dvw;
        height: 100dvh;
        background: transparentize(colors.$intense-black, $amount: 0.2);
        border-bottom: none;
        animation: fadeIn 0.5s;
        z-index: 3;

        .navbar__menu-item {
          display: block !important;
        }

        .navbar__menu-link {
          font-size: 4.5dvw;
          line-height: 27.07px;
          letter-spacing: 0.05em;
        }
      }

      &-item {
        @include breakpoints.respond(xs) {
          &:not(.navbar__menu-item--active) {
            display: none;
          }
        }
      }
    }

    &__menu-link {
      font-size: 1.1dvw;
      line-height: 23px;
      letter-spacing: 0.05em;
      @include breakpoints.respond(m) {
        font-size: 16px;
        line-height: 16.66px;
      }
      @include breakpoints.respond(s) {
        font-size: 1.5dvw;
        line-height: inherit;
      }
      @include breakpoints.respond(xs) {
        font-size: 14px;
        font-weight: 700;
        line-height: 14.57px;
      }
    }

    &__burger-menu {
      &-toggle {
        display: none;
        @include breakpoints.respond(xs) {
          position: absolute;
          top: 11px;
          left: 20px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          border-radius: 2px;
          cursor: pointer;
          z-index: 4;
        }

        &--hidden {
          &:focus-visible ~ .navbar__burger-menu-toggle {
            outline: 2px solid colors.$intense-white;
          }

          &:checked {
            ~ .navbar__burger-menu-toggle .navbar__burger-menu-bar {
              &:nth-of-type(1) {
                transform: translateY(8px) rotate(45deg);
              }
              &:nth-of-type(2) {
                opacity: 0;
              }
              &:nth-of-type(3) {
                transform: translateY(-4px) rotate(-45deg);
              }
            }
          }
        }
      }

      &-bar {
        height: 2px;
        width: 20px;
        border-radius: 2px;
        background-color: colors.$intense-white;
        transition: 0.5s;

        &:nth-child(2) {
          width: 13px;
        }
      }
    }

    .overlay {
      height: 38.6px;
    }
  }

  .progress {
    @include breakpoints.respond(xs) {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    right: 1dvw;
    top: 1dvw;

    svg {
      width: 1dvw;
      height: 1dvw;
    }

    @include breakpoints.respond(xs) {
      position: static;

      svg {
        width: 5dvw;
        height: 5dvw;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
