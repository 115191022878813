@use "@/styles/colors";
@use "@/styles/utils";
@use "@/styles/breakpoints";

.club {
  color: colors.$intense-black;

  .divider {
    &--horizontal {
      border-image: linear-gradient(130deg, colors.$gray 35%, colors.$intense-black 100%) 1;
    }

    &--vertical {
      border-color: darken(colors.$gray, 20%);
    }
  }

  .section {
    &-wrapper {
      flex-direction: row-reverse;
      height: 100%;
      display: flex;
      flex: 1 1;
    }

    &__header-title {
      background: -webkit-linear-gradient(130deg, colors.$intense-black 25%, colors.$gray 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &__content {
      flex: 1;
      padding: 65px 23px 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 49px;
    }

    &__text {
      padding: 0;
    }

    &__button {
      position: relative;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      gap: 1dvw;
      @include breakpoints.respond(xs) {
        gap: 10px;
      }
      text-align: center;

      &::after {
        content: "";
        left: 0;
        bottom: 0;
        width: 0;
        height: 5px;
        position: absolute;
        transition: width 0.1s linear;
        background: transparentize(colors.$intense-white, 0.1);
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      svg {
        width: 1.3dvw;
        height: 1.3dvw;
        @include breakpoints.respond(xs) {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &__gallery {
    flex: 2.4;
    padding: 65px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 3dvw;
    @include utils.positionElements(7);

    &-item {
      max-width: 11dvw;
      max-height: 32dvh;
    }
  }

  @include breakpoints.respond(m) {
    .section {
      &__content {
        padding: 12px 30px;
        justify-content: space-around;
        gap: 0;
      }

      &__text {
        line-height: 3dvh;
      }

      &__button {
        padding: 11px 17px;
      }
    }

    &__gallery {
      padding: 24px 65px;
    }
  }

  @include breakpoints.respond(s) {
    .section {
      &__content {
        padding: 20px;
      }

      &__text {
        font-size: 1.5dvw;
        line-height: 2.5dvw;
      }

      &__button {
        svg {
          width: 20px;
          height: 20px;
          flex: 1 0 20px;
        }
      }
    }

    &__gallery {
      padding: 20px;

      &-item {
        max-width: 15dvw;
        max-height: 26dvh;
      }
    }
  }

  @include breakpoints.respond(xs) {
    .section {
      &-wrapper {
        padding: 20px;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 20px;
      }

      &__content {
        padding: 0;
        flex: none;
        gap: 3dvh;
      }

      &__text {
        font-weight: 700;
      }
    }

    &__gallery {
      padding: 0;
      flex: none;

      &-item {
        display: none;
        animation: none !important;

        &:first-child {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
