@use "@/styles/utils";
@use "@/styles/breakpoints";

.spirit {
  .row {
    justify-content: space-around;
    @include breakpoints.respond(m, s) {
      height: auto;
      max-width: none;
      flex: 0 1 auto;
      gap: 10px;
    }
  }

  .section {
    &__content {
      padding: 30px 58px 55px 78px;
      gap: 55px;
    }

    &__image {
      width: 20dvw;
    }

    &__text {
      text-align: right;
      @include utils.textCorners(right top, right bottom);
    }

    @include breakpoints.respond(m) {
      &__content {
        padding: 24px 29px 20px 30px;
      }
    }

    @include breakpoints.respond(s) {
      &__content {
        padding: 20px;
      }
    }

    @include breakpoints.respond(xs) {
      min-height: 0;

      &__content {
        padding: 30px 24px;
      }

      &__text {
        text-align: left;
        @include utils.textCorners(left top, right top, right bottom, left bottom);
      }

      .row {
        justify-content: center;
        gap: 0;
      }

      &__image {
        width: 100%;

        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
