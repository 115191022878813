@use "@/styles/utils";
@use "@/styles/colors";
@use "@/styles/breakpoints";

.bikes-models {
  .section {
    &__content {
      height: 100%;
      display: flex;
      flex: 1 1;

      &-wrapper {
        flex: 1.2;
        box-sizing: border-box;
        padding: 57px 40px;
      }
    }

    &__text {
      @include utils.textCorners(left top, left bottom);
    }
  }

  .specifictaions {
    flex: 3;
    padding: 7dvh 29px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 1dvh;

      &-caption {
        font-family: Hanson;
        font-size: 2dvh;
        @include breakpoints.respond(xs) {
          line-height: 21px;
        }
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 1dvh;
        font-family: Manrope;
        max-height: 0;
        transition: max-height 0.8s ease-in-out;
        overflow: hidden;

        &__item {
          display: flex;
          flex-direction: column;

          &-name {
            font-family: Hanson;
            font-size: 1.3dvh;
            text-transform: uppercase;
            line-height: 16.66px;
            letter-spacing: 0.05em;
            opacity: 0.5;
          }

          &-value {
            font-size: 2dvh;
            font-weight: 500;
          }
        }
      }
    }
  }

  @include breakpoints.respond(m) {
    .section {
      &__content-wrapper {
        padding: 40px 24px 0 26px;
      }
    }

    .specifictaions {
      padding: 3dvh 18px;

      &__item {
        &-list {
          gap: 1dvh;
        }

        &-caption {
          font-size: 1.5dvw;
        }

        &-image {
          width: 35dvh;
          align-self: center;
        }

        &-list__item {
          &-name {
            font-size: 0.9dvw;
          }

          &-value {
            font-size: 1.1dvw;
          }
        }
      }
    }
  }

  @include breakpoints.respond(s) {
    .section {
      &__content {
        &-wrapper {
          padding: 10px;
        }
      }
    }
  }

  @include breakpoints.respond(xs) {
    .section {
      &__content {
        flex-direction: column;
        padding: 8px 33px;

        &-wrapper {
          padding: 0;
        }
      }

      &__text {
        padding: 0;
        @include utils.dividerLine(100%, 11px, true);

        &::before {
          display: none;
        }
      }
    }

    .specifictaions {
      padding: 0;
      margin-top: 25px;

      &__item {
        &-image {
          max-width: 75%;
          align-self: center;
        }

        &-list {
          padding-left: 40px;

          &__item {
            &-name {
              line-height: inherit;
            }

            &-value {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
